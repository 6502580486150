<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2" style="padding-bottom: 18rem !important;">

      <b-link class="brand-logo" style="height: 150px;">
        <b-img src="@/assets/images/wesoft_1.png" style="object-fit: contain; object-position: center bottom;" class="w-100"/>
      </b-link>

      <b-card class="mb-0">
        <b-form class="mt-2">

          <b-form-group label-for="username" :label="$t('login.username')">
            <b-form-input
              ref="username" id="username" v-model="username" name="username" placeholder="" autofocus
              @keyup.enter="$refs.password.focus()"
            />
          </b-form-group>

          <b-form-group label-for="password" :label="$t('login.password')">
            <b-form-input
              ref="password" id="password" type="password" v-model="password" name="password" placeholder=""
              @keyup.enter="login"
            />
          </b-form-group>

          <b-button variant="primary" block :disabled="username.trim() === '' || password.trim() === ''" @click="login">
            {{ $t('login.submit') }}
          </b-button>

        </b-form>
      </b-card>

    </div>
  </div>
</template>

<script>
import common from '@/common'
import router from '@/router'
import { srHub, srStart, srStop, srUpdateFilter, srStateMap } from '@/libs/signalr-hub'

export default {
  components: {},

  data() {
    return {
      username: process.env.VUE_APP_API_ENV === 'Dev' ? 'sa@wehealth.com' : '',
      password: process.env.VUE_APP_API_ENV === 'Dev' ? 'Wesoft123' : '',
    }
  },

  beforeRouteEnter(to, from, next) {
    // 用戶訪問登錄頁面時，若存在 token，則跳轉到首頁
    if (common.getAccessToken() !== null) {
      router.push({ name: common.getMenuFirstRoute() })
    }
    next()
  },

  methods: {
    login: function () {
      if (this.$store.state.app.loadingNum > 0) {
        return false
      }

      // API 暫未支持在 body 中傳遞參數
      // common.apiPostData('/WebLogin/GenToken', {
      //   username: this.username,
      //   password: this.password,
      // })

      const fullUrl = '/WebLogin/GenToken?userName=' + encodeURIComponent(this.username) + '&password=' + encodeURIComponent(this.password)
      common.apiPostData(fullUrl)
        .then(res => {
          common.setAccessToken(res.token)
          common.setRefreshToken(res.refresh_token)
          common.setTokenExpireTime(res.expire_in - 200)

          // 获取个人信息
          common.apiGetData('/staff/GetProfile')
            .then(res => {
              common.setUserProfile(res.data.profile)
              common.setImpersonated(res.data.isImpersonated)

              const roles = res.data.profile.roles
              const first_role = this.getFirstAvailableRole(roles)
              if (!first_role) {
                return common.showAlert({
                  title: common.getI18n('facility.contractStatusTip_Expired'),
                  html: roles.map(role => `<div class="mt-05">${role.facilityName}</div>`).join('')
                })
              }

              common.setRole(first_role)
              let progress_permission = false
              let progress_menu = false

              // 獲取權限
              common.setPermissions({ facilityID: first_role.facilityID })
                .then(res => {
                  progress_permission = true
                })

              // SA 賬號沒有選擇院捨時默認選擇第一個公司第一個院捨
              if (common.isAdmin() && !common.getFacilityId()) {
                common.getCompanyOptions()
                  .then(res => {
                    if (res[0]) {
                      common.setAdminCompanyId(res[0].value)
                      common.getFacilityOptions(res[0].value)
                        .then(res_facility => {
                          if (res_facility[0]) {
                            common.setAdminFacilityId(res_facility[0].value)
                          }
                        })
                    }
                  })
              }
              // else {
              //   common.setAdminFacilityId(common.getRole().facilityID)
              // }

              // 獲取菜單
              common.refreshMenu(first_role.facilityID)
                .then(res => {
                  progress_menu = true
                })

              const login_inter = setInterval(function () {
                if (progress_permission && progress_menu) {
                  clearInterval(login_inter)
                  localStorage.setItem('deploy_version', common.config.deploy_version.toString())
                  common.refreshStore()
                  router.push({ name: common.getMenuFirstRoute() })
                  srStart(true)
                  // common.showLoading()
                  // location.reload()
                }
              }, 100)

            })
        })

    },
    getFirstAvailableRole: function(roles) {
      // return null;
      return roles.find(role => role.contractStatus !== 'Expired');
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
